<template>
  <div class="about">
    <h1>This is an about page</h1>
  </div>
</template>

<style scoped>
  .about{
    /*border: 2px solid yellow;*/
    width: 95%;
    margin: auto;
  }
</style>
